import { 
    format, 
    formatRelative as formatRelativeOriginal,
    parse
} from 'date-fns';

import { es } from 'date-fns/esm/locale';


function beautify(date) {
    return format(new Date(date), 'yyyy-MM-dd');
}

function time(date) {
    return format(new Date(date), 'HH:mm');
}

const formatRelativeLocale = {
    lastWeek: "eeee 'pasado",
    yesterday: "'Ayer'",
    today: "'Hoy'",
    tomorrow: "'Mañana'",
    nextWeek: "'próximo' eeee",
    other: 'dd-MM-yyyy',
};

const locale = {
    ...es,
    formatRelative: token => formatRelativeLocale[token],
};

function formatRelative(date, relative) {
    return formatRelativeOriginal(date, relative, { locale });
}


export {
    beautify,
    time,
    format,
    formatRelative,
    parse
};